
.box-header {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767.98px) {
    display: block;
  }

  &.box-styled {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1.5rem;
  }

  @media screen and (max-width: 1200px) {
    .float-left {
      margin-bottom: 10px;
    }
  }
}
